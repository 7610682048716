/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Utils from '../../services/utils';
import AccountsAndPortfolioView from './accounts-and-portfolio-view';
import { PortfolioChangeTypes } from '../../ducks/portfolio-changes';

class AccountsAndPortfolio extends Component {
  static propTypes = {
    firstNAVDate: PropTypes.string,
    isFetching: PropTypes.bool,
    getBalances: PropTypes.func,
    getPortfolioChanges: PropTypes.func,
    clearBalances: PropTypes.func,
    clearPortfolioChanges: PropTypes.func,
    portfolioChangesDaily: PropTypes.arrayOf(PropTypes.shape()),
    portfolioChangesHourly: PropTypes.arrayOf(PropTypes.shape()),
    portfolioLatestDate: PropTypes.string,
    balances: PropTypes.arrayOf(PropTypes.shape()),
    originalCurrencies: PropTypes.arrayOf(PropTypes.array),
    isUpdatingProgresses: PropTypes.bool,
    progresses: PropTypes.arrayOf(PropTypes.shape()),
    getProgresses: PropTypes.func,
    getCurrencies: PropTypes.func,
    clearProgresses: PropTypes.func,
    getOriginalCurrencies: PropTypes.func
  };

  // Data manipulation functions

  static addGroupingToBalances(balances, portfolioChangesDaily) {
    const sumBalanceEur = AccountsAndPortfolio.calculateSum(
      balances,
      'balanceEur',
      'unconfirmedBalanceEur'
    );

    const navRecord = portfolioChangesDaily.find(
      pc => pc.key === PortfolioChangeTypes.totalNav
    );

    function capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const nav = navRecord?.value;
    return balances.map(balance => ({
      // For accounts tab
      account:
        balance.exchangeName === 'Private Addresses' &&
        balance.source === 'private'
          ? balance.exchangeName
          : balance.exchangeName === 'Private Addresses'
          ? capitalizeFirstLetter(balance.source)
          : balance.exchangeName || balance.source,

      exchangeCode: balance.exchangeCode,
      // For instruments tab
      instrumentType: balance.isStable ? 'Stablecoin (crypto)' : balance.type,
      instrument:
        balance.pair !== null
          ? balance.pair.toUpperCase()
          : balance.symbol.toUpperCase(),
      // For blockchains tab
      blockchain: balance.blockchain || 'Non-blockchain instruments',

      // Base balance data
      id: balance.id,
      isAlgo: balance.isAlgo,
      pnlIsStable: balance.pnlIsStable,
      pnlSymbol: balance.pnlSymbol,
      underlyingSymbol: balance.underlyingSymbol,
      underlyingCurId: balance.underlyingCurId,
      type: balance.type,
      isMargin: balance.isMargin,
      isOption: balance.isOption,
      currencyType: balance.currencyType,
      subaccount: balance.subaccount,
      label: balance.label,
      amount:
        balance.unconfirmedAmount === 0
          ? balance.amount
          : balance.unconfirmedAmount,
      unconfirmedAmount:
        balance.unconfirmedAmount === 0
          ? balance.amount
          : balance.unconfirmedAmount,
      balanceEur: balance.balanceEur,
      unconfirmedBalanceEur:
        balance.unconfirmedBalanceEur || balance.balanceEur,
      value:
        nav && nav !== 0
          ? ((balance.unconfirmedBalanceEur || balance.balanceEur) / nav) * 100
          : 0,
      change24hPercentage: balance.change24,
      positionExposureEur: balance.positionExposureEur,
      pnlExposureEur: balance.pnlExposureEur,
      exposureEur: balance.exposureEur,
      cryptoExposure:
        balance.cryptoExposure && nav && nav !== 0
          ? ((balance.cryptoExposure || 0) / nav) * 100
          : 0,
      unconfirmedExposureEur:
        balance.unconfirmedExposureEur || balance.exposureEur,
      lastUpdate: balance.updatedAt,
      sourceTime: balance.sourceTime,
      isStable: balance.isStable,
      pnlCurrencyType: balance.pnlCurrencyType,
      side: balance.side,

      // Hover popup data
      hoverPopup: {
        position: {
          exposure: Utils.formatToMaxDecimal(balance.positionExposure),
          exposureCurrency: balance.positionExposureSymbol?.toUpperCase(),
          underlyingCurrency: balance.underlyingSymbol
        },
        pnl: {
          exposure: !balance.isOption ? balance.pnlExposure : null,
          exposureCurrency: !balance.isOption
            ? balance.pnlExposureSymbol?.toUpperCase()
            : null,
          underlyingCurrency: !balance.isOption ? balance.pnlSymbol : null
        }
      },

      // helper data for instrument and portfolios table
      sumBalanceEur,
      nav
    }));
  }

  static calculateSum(balances, propName, propName2) {
    let sum = 0;
    balances.forEach(balance => {
      sum += balance[propName2] || balance[propName];
    });
    return sum;
  }

  constructor(props) {
    const series = JSON.parse(process.env.REACT_APP_SERIES);
    super(props);
    this.state = {
      firstRequest: true,
      date: moment.utc().minutes(0).seconds(0).millisecond(0),
      isAddModalOpen: false,
      isVerifyExposureModalOpen: false,
      isExportModalOpen: false,
      requiredPortfolioData: [
        PortfolioChangeTypes.totalNav,
        ...series.map(s => PortfolioChangeTypes[`navPerShare${s}`]),
        PortfolioChangeTypes.totalAssets,
        PortfolioChangeTypes.grossCryptoExposure,
        PortfolioChangeTypes.netCryptoExposure,
        PortfolioChangeTypes.grossStablecoinExposure,
        PortfolioChangeTypes.benchmark
      ]
    };

    this.openAddModal = this.openAddModal.bind(this);
    this.closeAddModal = this.closeAddModal.bind(this);
    this.openVerifyExposureModal = this.openVerifyExposureModal.bind(this);
    this.closeVerifyExposureModal = this.closeVerifyExposureModal.bind(this);
    this.openExportModal = this.openExportModal.bind(this);
    this.closeExportModal = this.closeExportModal.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.loopProgresses = this.loopProgresses.bind(this);
    this.startLooping = this.startLooping.bind(this);
  }

  // ---------------------------
  // Component lifecycle methods
  // ---------------------------

  componentDidMount() {
    const {
      getBalances,
      getPortfolioChanges,
      getProgresses,
      getOriginalCurrencies
    } = this.props;
    const { requiredPortfolioData } = this.state;

    // Send the first request before the loop starts
    getProgresses('manual-balance');
    this.loopedProgressesCall = setInterval(this.loopProgresses, 2000);

    getOriginalCurrencies();

    getBalances();
    getPortfolioChanges(
      requiredPortfolioData,
      moment().subtract(1, 'day'),
      true
    );
  }

  componentWillUnmount() {
    const { clearBalances, clearPortfolioChanges, clearProgresses } =
      this.props;
    clearInterval(this.loopedProgressesCall);
    clearBalances();
    clearPortfolioChanges();
    clearProgresses();
  }

  // ----------------
  // Helper functions
  // ----------------

  handleDateChange(newDate) {
    const { date, requiredPortfolioData } = this.state;
    const { getBalances, getPortfolioChanges } = this.props;
    const lNewDate = moment(newDate);

    // If selected date (newDate) is different than the date stored in state
    // -> Refetch data
    // -> Set state date to newly selected date

    if (
      moment(lNewDate).format('YYYY-MM-DDTHH') !==
      moment(date).format('YYYY-MM-DDTHH')
    ) {
      // if same day
      if (
        moment(lNewDate).format('YYYY-MM-DD') ===
        moment(date).format('YYYY-MM-DD')
      ) {
        // 1-24h format
        if (newDate.format('HH') === '00') {
          lNewDate.add(1, 'd');
        }
        // if another day and today
      } else if (
        moment(lNewDate).format('YYYY-MM-DD') ===
        moment.utc().format('YYYY-MM-DD')
      ) {
        // set last hour
        lNewDate.set('hour', moment.utc().format('HH'));
        newDate.set('hour', moment.utc().format('HH'));
        // if another day and not today
      } else {
        // New day, set hour to 24:00
        lNewDate.set('hour', 24);
        newDate.set('hour', 0);
      }
      // Refetch data
      getBalances(lNewDate.format('YYYYMMDDHH'));
      getPortfolioChanges(
        requiredPortfolioData,
        lNewDate.clone().subtract(1, 'day').valueOf(),
        true
      );
      this.setState({
        date: newDate.minutes(0).seconds(0).millisecond(0)
      });
    }
  }

  // ------------------
  // Loop call function
  // ------------------

  loopProgresses() {
    const { firstRequest, requiredPortfolioData, date } = this.state;
    const {
      isUpdatingProgresses,
      progresses,
      getProgresses,
      getBalances,
      getPortfolioChanges,
      getCurrencies
    } = this.props;
    if (progresses && progresses.length === 0 && !isUpdatingProgresses) {
      // Check whether the request was finished and no data is returned.
      // -> If so, clear the loop and gather balances
      clearInterval(this.loopedProgressesCall);

      // If it is not the first request
      // -> call getBalances and getPortfolioChanges with the date from state
      // -> also call getCurrencies (if new instrument is added)
      if (!firstRequest) {
        getBalances(date.format('YYYYMMDDHH'));
        getPortfolioChanges(
          requiredPortfolioData,
          date.clone().subtract(1, 'day').valueOf(),
          true
        );
        getCurrencies(true, true);
      }
    } else if (progresses && progresses.length > 0 && !isUpdatingProgresses) {
      // Check whether the request was finished and some data is returned.
      // -> If so, gather again and stay in the loop
      getProgresses('manual-balance');
    }

    // If firstRequest is true
    if (firstRequest) {
      // Set it to false
      this.setState({ firstRequest: false });
    }
  }

  startLooping() {
    // Starts looped progress call. Initiated by:
    // -> Adding a new manual balance
    // -> Editing an existing manual balance
    const { getProgresses } = this.props;
    setTimeout(() => {
      getProgresses('manual-balance');
      this.loopedProgressesCall = setInterval(this.loopProgresses, 2000);
    }, 200);
  }

  // ---------------
  // Modal functions
  // ---------------

  openAddModal() {
    this.setState({ isAddModalOpen: true });
  }

  openExportModal() {
    this.setState({ isExportModalOpen: true });
  }

  openVerifyExposureModal() {
    this.setState({ isVerifyExposureModalOpen: true });
  }

  closeAddModal() {
    this.setState({ isAddModalOpen: false });
  }

  closeExportModal() {
    this.setState({ isExportModalOpen: false });
  }

  closeVerifyExposureModal() {
    this.setState({ isVerifyExposureModalOpen: false });
  }

  // ------
  // Render
  // ------

  render() {
    const {
      firstNAVDate,
      isFetching,
      portfolioChangesDaily,
      portfolioChangesHourly,
      portfolioLatestDate,
      balances,
      originalCurrencies,
      progresses,
      isUpdatingProgresses
    } = this.props;

    const groupedBalances = AccountsAndPortfolio.addGroupingToBalances(
      balances,
      portfolioChangesDaily,
      portfolioChangesHourly
    );

    const {
      date,
      isAddModalOpen,
      isExportModalOpen,
      isVerifyExposureModalOpen
    } = this.state;

    const buttons = [
      {
        name: 'Balances',
        icon: 'download',
        action: () => this.openExportModal()
      },
      {
        name: 'Verify exposure',
        icon: 'upload',
        action: () => this.openVerifyExposureModal(),
        isDisabled: isUpdatingProgresses || progresses.length > 0
      },
      {
        name: 'Add data',
        icon: 'upload',
        action: () => this.openAddModal(),
        isDisabled: isUpdatingProgresses || progresses.length > 0
      }
    ];

    return (
      <AccountsAndPortfolioView
        isAddModalOpen={isAddModalOpen}
        isExportModalOpen={isExportModalOpen}
        isVerifyExposureModalOpen={isVerifyExposureModalOpen}
        closeAddModal={this.closeAddModal}
        closeExportModal={this.closeExportModal}
        closeVerifyExposureModal={this.closeVerifyExposureModal}
        buttons={buttons}
        handleDateChange={this.handleDateChange}
        date={date}
        originalCurrencies={originalCurrencies}
        portfoliosDaily={portfolioChangesDaily}
        portfoliosHourly={portfolioChangesHourly}
        portfolioLatestDate={portfolioLatestDate}
        firstDate={firstNAVDate}
        groupedBalances={groupedBalances}
        isFetching={isFetching}
        startLooping={this.startLooping}
      />
    );
  }
}

export default AccountsAndPortfolio;
