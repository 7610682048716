// Base Request types
// Each Action which hase a type `REQUEST` runs into the request-saga
// Others need to be handled separately
export const REQUEST = 'REQUEST';
export const REQUEST_LONG = 'REQUEST_LONG';
export const Types = {
  REQUEST,
  REQUEST_LONG
};

//---------------------------------------------------------
// Requests for:
// AUTHORIZATION
// These have separate saga

export const GET_TOKEN = 'GET_TOKEN';
export const getToken = (credentials, isOld) => ({
  type: GET_TOKEN,
  payload: {
    credentials,
    isOld
  }
});

export const VALIDATE_TOKEN = 'VALIDATE_TOKEN';
export const validateToken = () => ({
  type: VALIDATE_TOKEN
});

//---------------------------------------------------------
// Requests for:
// DOWNLOADING CSV DATA
// These have separate saga

export const DOWNLOAD_CSV = 'DOWNLOAD_CSV';
export const downloadCSV = query => ({
  type: DOWNLOAD_CSV,
  payload: { query }
});

//---------------------------------------------------------
// Requests for:
// BALANCES

export const GET_BALANCES = 'GET_BALANCES';
export const getBalances = (packageId = null) => ({
  type: REQUEST,
  payload: {
    action: GET_BALANCES,
    variables: {
      packageId
    }
  }
});

export const GET_EXPOSURE_MODAL_BALANCES = 'GET_EXPOSURE_MODAL_BALANCES';
export const getExposureModalBalances = (packageId = null) => ({
  type: REQUEST,
  payload: {
    action: GET_EXPOSURE_MODAL_BALANCES,
    variables: {
      packageId
    }
  }
});

export const RECORD_EXPOSURE = 'RECORD_EXPOSURE';
export const recordExposure = (packageId = null) => ({
  type: REQUEST,
  payload: {
    action: RECORD_EXPOSURE,
    variables: {
      packageId
    }
  }
});

export const ADD_MANUAL_BALANCE = 'ADD_MANUAL_BALANCE';
export const addManualBalance = body => ({
  type: REQUEST_LONG,
  payload: {
    action: ADD_MANUAL_BALANCE,
    variables: { body }
  }
});

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const deleteAccount = body => ({
  type: REQUEST,
  payload: {
    action: DELETE_ACCOUNT,
    variables: { body }
  }
});

export const ADD_ADDRESS = 'ADD_ADDRESS';
export const addAddress = payload => ({
  type: REQUEST_LONG,
  payload: {
    action: ADD_ADDRESS,
    variables: { payload }
  }
});

export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const deleteAddress = payload => ({
  type: REQUEST,
  payload: {
    action: DELETE_ADDRESS,
    variables: { payload }
  }
});

export const GET_INSTRUMENTS = 'GET_INSTRUMENTS';
export const getInstruments = payload => ({
  type: REQUEST,
  payload: {
    action: GET_INSTRUMENTS,
    variables: { payload }
  }
});

//---------------------------------------------------------
// Requests for:
// BALANCES LOG

export const GET_BALANCES_LOG = 'GET_BALANCES_LOG';
export const getBalancesLog = (
  start,
  end,
  currency = null,
  label = null,
  dailyLast = null,
  pair = null,
  accountName = null
) => ({
  type: REQUEST,
  payload: {
    action: GET_BALANCES_LOG,
    variables: {
      start,
      end,
      currency,
      label,
      dailyLast,
      pair,
      accountName
    }
  }
});

//---------------------------------------------------------
// Requests for:
// PORTFOLIO CHANGES

export const GET_PORTFOLIO_CHANGES = 'GET_PORTFOLIO_CHANGES';
export const getPortfolioChanges = (
  types = [],
  date = null,
  isPrevNav = false
) => ({
  type: REQUEST,
  payload: {
    action: GET_PORTFOLIO_CHANGES,
    variables: {
      types,
      date,
      isPrevNav
    }
  }
});

export const GET_EXPOSURE_MODAL_PORTFOLIO_CHANGES =
  'GET_EXPOSURE_MODAL_PORTFOLIO_CHANGES';
export const getExposureModalPortfolioChanges = (
  types = [],
  date = null,
  isPrevNav = false
) => ({
  type: REQUEST,
  payload: {
    action: GET_EXPOSURE_MODAL_PORTFOLIO_CHANGES,
    variables: {
      types,
      date,
      isPrevNav
    }
  }
});

//---------------------------------------------------------
// Requests for:
// BLOCKCHAINS

export const GET_BLOCKCHAINS = 'GET_BLOCKCHAINS';
export const getBlockchains = () => ({
  type: REQUEST,
  payload: {
    action: GET_BLOCKCHAINS
  }
});

//---------------------------------------------------------
// Requests for:
// CURRENCIES

export const GET_CURRENCIES = 'GET_CURRENCIES';
export const getCurrencies = (withBlockchain = false, withSymbol = false) => ({
  type: REQUEST,
  payload: {
    action: GET_CURRENCIES,
    variables: {
      withBlockchain,
      withSymbol
    }
  }
});

//---------------------------------------------------------
// Requests for:
// CURRENCIES

export const GET_ORIGINAL_CURRENCIES = 'GET_ORIGINAL_CURRENCIES';
export const getOriginalCurrencies = () => ({
  type: REQUEST,
  payload: {
    action: GET_ORIGINAL_CURRENCIES
  }
});

//---------------------------------------------------------
// Requests for:
// CHART DATA

export const GET_CHART_DATA = 'GET_CHART_DATA';
export const getChartData = (type, from, to) => ({
  type: REQUEST,
  payload: {
    action: GET_CHART_DATA,
    variables: {
      type,
      from,
      to
    }
  }
});

export const GET_NET_EXPOSURE_MODAL_DATA = 'GET_NET_EXPOSURE_MODAL_DATA';
export const getNetExposureModalData = (from, to) => ({
  type: REQUEST,
  payload: {
    action: GET_NET_EXPOSURE_MODAL_DATA,
    variables: {
      type: 'netExposure',
      from,
      to
    }
  }
});

export const GET_GROSS_EXPOSURE_MODAL_DATA = 'GET_GROSS_EXPOSURE_MODAL_DATA';
export const getGrossExposureModalData = (from, to) => ({
  type: REQUEST,
  payload: {
    action: GET_GROSS_EXPOSURE_MODAL_DATA,
    variables: {
      type: 'grossExposure',
      from,
      to
    }
  }
});

//---------------------------------------------------------
// Requests for:
// TRANSACTION HISTORY

export const GET_TRANSACTION_HISTORY = 'GET_TRANSACTION_HISTORY';
export const getTransactionHistory = (start, end) => ({
  type: REQUEST,
  payload: {
    action: GET_TRANSACTION_HISTORY,
    variables: {
      start,
      end
    }
  }
});

//---------------------------------------------------------
// Requests for:
// RATES

export const GET_RATES = 'GET_RATES';
export const getRates = () => ({
  type: REQUEST,
  payload: {
    action: GET_RATES
  }
});

export const GET_EUR_RATE = 'GET_EUR_RATE';
export const getEurRate = (currencyId, date) => ({
  type: REQUEST,
  payload: {
    action: GET_EUR_RATE,
    variables: { currencyId, date }
  }
});

export const UPDATE_RATE_SOURCE_STATUS = 'UPDATE_RATE_SOURCE_STATUS';
export const updateRateSourceStatus = id => ({
  type: REQUEST,
  payload: {
    action: UPDATE_RATE_SOURCE_STATUS,
    variables: { id }
  }
});

//---------------------------------------------------------
// Requests for:
// FEES

export const GET_FEES = 'GET_FEES';
export const getFees = (date = null) => ({
  type: REQUEST,
  payload: {
    action: GET_FEES,
    variables: { date }
  }
});

//---------------------------------------------------------
// Requests for:
// BACKGROUND DATA

export const GET_BACKGROUND_DATA = 'GET_BACKGROUND_DATA';
export const getBackgroundData = () => ({
  type: REQUEST,
  payload: {
    action: GET_BACKGROUND_DATA
  }
});

//---------------------------------------------------------
// Requests for:
// PROGRESSES

export const GET_PROGRESSES = 'GET_PROGRESSES';
export const getProgresses = (type = null) => ({
  type: REQUEST,
  payload: {
    action: GET_PROGRESSES,
    variables: { type }
  }
});

//---------------------------------------------------------
// Requests for:
// SETTINGS

export const GET_SETTINGS = 'GET_SETTINGS';
export const getSettings = data => ({
  type: REQUEST,
  payload: {
    action: GET_SETTINGS,
    variables: { data }
  }
});

export const GET_FUND_START_DATE = 'GET_FUND_START_DATE';
export const getFundStartdate = data => ({
  type: REQUEST,
  payload: {
    action: GET_FUND_START_DATE,
    variables: { data }
  }
});

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const updateSettings = (body, modelName) => ({
  type: REQUEST,
  payload: {
    action: UPDATE_SETTINGS,
    variables: { body, modelName }
  }
});

export const ADD_SETTINGS = 'ADD_SETTINGS';
export const addSettings = (body, modelName) => ({
  type: REQUEST,
  payload: {
    action: ADD_SETTINGS,
    variables: { body, modelName }
  }
});

export const DELETE_SETTINGS = 'DELETE_SETTINGS';
export const deleteSettings = (id, modelName) => ({
  type: REQUEST,
  payload: {
    action: DELETE_SETTINGS,
    variables: { id, modelName }
  }
});

//---------------------------------------------------------

export const ActionTypes = {
  GET_BALANCES,
  GET_EXPOSURE_MODAL_BALANCES,
  RECORD_EXPOSURE,
  ADD_MANUAL_BALANCE,
  DELETE_ACCOUNT,
  ADD_ADDRESS,
  DELETE_ADDRESS,
  GET_PORTFOLIO_CHANGES,
  GET_EXPOSURE_MODAL_PORTFOLIO_CHANGES,
  GET_BLOCKCHAINS,
  GET_CURRENCIES,
  GET_ORIGINAL_CURRENCIES,
  GET_CHART_DATA,
  GET_NET_EXPOSURE_MODAL_DATA,
  GET_GROSS_EXPOSURE_MODAL_DATA,
  GET_BALANCES_LOG,
  GET_TRANSACTION_HISTORY,
  GET_RATES,
  UPDATE_RATE_SOURCE_STATUS,
  GET_FEES,
  GET_BACKGROUND_DATA,
  GET_SETTINGS,
  GET_FUND_START_DATE,
  UPDATE_SETTINGS,
  ADD_SETTINGS,
  DELETE_SETTINGS,
  DOWNLOAD_CSV,
  GET_TOKEN,
  VALIDATE_TOKEN,
  GET_PROGRESSES,
  GET_EUR_RATE,
  GET_INSTRUMENTS
};
