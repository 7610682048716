import { GET_CURRENCIES, GET_ORIGINAL_CURRENCIES, REQUEST } from '../request';

export const Types = {
  GET_CURRENCIES_SUCCESS: 'GET_CURRENCIES_SUCCESS',
  GET_CURRENCIES_FAILED: 'GET_CURRENCIES_FAILED',
  CLEAR_CURRENCIES: 'CLEAR_CURRENCIES',
  GET_ORIGINAL_CURRENCIES_SUCCESS: 'GET_ORIGINAL_CURRENCIES_SUCCESS',
  GET_ORIGINAL_CURRENCIES_FAILED: 'GET_ORIGINAL_CURRENCIES_FAILED'
};

const initialState = {
  isUpdating: false,
  isFetching: false,
  error: null,
  originalCurrencies: [],
  data: []
};

const currencies = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case REQUEST:
      if (
        action.payload &&
        action.payload.action &&
        [GET_CURRENCIES, GET_ORIGINAL_CURRENCIES].includes(
          action.payload.action
        )
      ) {
        return {
          ...state,
          isFetching: true
        };
      }
      return state;

    case Types.GET_CURRENCIES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        data: (action.payload && action.payload.data) || []
      };
    }

    case Types.GET_ORIGINAL_CURRENCIES_FAILED:
    case Types.GET_CURRENCIES_FAILED: {
      return {
        ...state,
        isFetching: false,
        error: action.payload && action.payload.message
      };
    }

    case Types.GET_ORIGINAL_CURRENCIES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        originalCurrencies: (action.payload && action.payload.data) || []
      };
    }

    case Types.CLEAR_CURRENCIES: {
      return { ...initialState, originalCurrencies: state.originalCurrencies };
    }

    default:
      return state;
  }
};

export const clearCurrencies = () => ({
  type: Types.CLEAR_CURRENCIES
});

export const Actions = {
  clearCurrencies
};

export default currencies;
