import BalancesApi from './balances';
import PortfolioChangesApi from './portfolio-changes';
import BlockchainsApi from './blockchains';
import { ActionTypes } from '../ducks/request';
import CurrenciesApi from './currencies';
import ChartDataApi from './chart-data';
import BalancesLogApi from './balances-log';
import TransactionHistoryApi from './transactions';
import RatesApi from './rates';
import FeesApi from './fees';
import BackgroundDataApi from './background-data';
import SettingsApi from './settings';
import CSVDownloadApi from './csv-download';
import AuthApi from './auth';
import ProgressesApi from './progress';

export default class Api {
  static request = (action, variables) => {
    switch (action) {
      case ActionTypes.GET_BALANCES:
      case ActionTypes.GET_EXPOSURE_MODAL_BALANCES: {
        const { packageId } = variables;
        return BalancesApi.get(packageId);
      }
      case ActionTypes.RECORD_EXPOSURE: {
        return BalancesApi.recordExposure();
      }
      case ActionTypes.ADD_MANUAL_BALANCE: {
        const { body } = variables;
        return BalancesApi.addManual(body);
      }
      case ActionTypes.DELETE_ACCOUNT: {
        const { body } = variables;
        return BalancesApi.deleteAccount(body);
      }
      case ActionTypes.ADD_ADDRESS: {
        const { payload } = variables;
        return BalancesApi.addAddress(payload);
      }
      case ActionTypes.DELETE_ADDRESS: {
        const { payload } = variables;
        return BalancesApi.deleteAddress(payload);
      }
      case ActionTypes.GET_INSTRUMENTS: {
        const { payload } = variables;
        return BalancesApi.getInstruments(payload);
      }
      case ActionTypes.GET_PORTFOLIO_CHANGES:
      case ActionTypes.GET_EXPOSURE_MODAL_PORTFOLIO_CHANGES: {
        const { types, date, isPrevNav } = variables;
        return PortfolioChangesApi.getPortfolioChangesNew(
          types,
          date,
          isPrevNav
        );
      }
      case ActionTypes.GET_BLOCKCHAINS: {
        return BlockchainsApi.getBlockchains();
      }
      case ActionTypes.GET_CURRENCIES: {
        const { withBlockchain, withSymbol } = variables;
        return CurrenciesApi.getCurrencies(withBlockchain, withSymbol);
      }
      case ActionTypes.GET_ORIGINAL_CURRENCIES: {
        return CurrenciesApi.getOriginalCurrencies();
      }
      case ActionTypes.GET_CHART_DATA: {
        const { type, from, to } = variables;
        return ChartDataApi.get(type, from, to);
      }
      case ActionTypes.GET_NET_EXPOSURE_MODAL_DATA:
      case ActionTypes.GET_GROSS_EXPOSURE_MODAL_DATA: {
        const { type, from, to } = variables;
        return ChartDataApi.get(type, from, to);
      }
      case ActionTypes.GET_BALANCES_LOG: {
        const { start, end, currency, label, dailyLast, pair, accountName } =
          variables;
        return BalancesLogApi.get(
          start,
          end,
          currency,
          label,
          dailyLast,
          pair,
          accountName
        );
      }
      case ActionTypes.GET_TRANSACTION_HISTORY: {
        const { start, end } = variables;
        return TransactionHistoryApi.get(start, end);
      }
      case ActionTypes.GET_RATES: {
        return RatesApi.get();
      }
      case ActionTypes.UPDATE_RATE_SOURCE_STATUS: {
        const { id } = variables;
        return RatesApi.updateSourceStatus(id);
      }
      case ActionTypes.GET_EUR_RATE: {
        const { currencyId, date } = variables;
        return RatesApi.getEurRate(currencyId, date);
      }
      case ActionTypes.GET_FEES: {
        const { date } = variables;
        return FeesApi.get(date);
      }
      case ActionTypes.GET_BACKGROUND_DATA: {
        return BackgroundDataApi.get();
      }
      case ActionTypes.GET_FUND_START_DATE:
      case ActionTypes.GET_SETTINGS: {
        const { data } = variables;
        return SettingsApi.get(data);
      }
      case ActionTypes.UPDATE_SETTINGS: {
        const { body, modelName } = variables;
        return SettingsApi.update(body, modelName);
      }
      case ActionTypes.ADD_SETTINGS: {
        const { body, modelName } = variables;
        return SettingsApi.add(body, modelName);
      }
      case ActionTypes.DELETE_SETTINGS: {
        const { id, modelName } = variables;
        return SettingsApi.delete(id, modelName);
      }
      case ActionTypes.DOWNLOAD_CSV: {
        const { query } = variables;
        return CSVDownloadApi.getCsvData(query);
      }
      case ActionTypes.GET_TOKEN: {
        const { credentials, isOld } = variables;
        if (isOld) {
          return AuthApi.getToken(credentials);
        }
        return AuthApi.getTokenNew(credentials);
      }
      case ActionTypes.GET_PROGRESSES: {
        const { type } = variables;
        return ProgressesApi.getProgresses(type);
      }
      case ActionTypes.VALIDATE_TOKEN: {
        return AuthApi.validateTokenNew();
      }
      default:
    }
    return null;
  };
}
