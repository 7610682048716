import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

class DatePickerButton extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    date: PropTypes.shape(),
    time: PropTypes.bool,
    unspecifiedFirst: PropTypes.bool,
    disabled: PropTypes.bool
  };

  render() {
    const { onClick, date, time, unspecifiedFirst, disabled } = this.props;

    if (!date && unspecifiedFirst) {
      return (
        <button
          type="button"
          className="btn btn-primary"
          onClick={onClick}
          disabled={disabled}
        >
          <i className="fa fa-calendar" />
          Unspecified
        </button>
      );
    }

    if (time) {
      return (
        <button
          type="button"
          className="btn btn-primary"
          onClick={onClick}
          disabled={disabled}
        >
          <i className="fa fa-calendar" />
          {moment(date).format('MMM DD YYYY kk:mm')}
        </button>
      );
    }

    return (
      <button
        type="button"
        className="btn btn-primary"
        onClick={onClick}
        disabled={disabled}
      >
        <i className="fa fa-calendar" />
        {moment(date).format('MMM DD YYYY')}
      </button>
    );
  }
}

export default DatePickerButton;
